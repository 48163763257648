import React, { useState, useEffect} from 'react'
import './Roster.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import { useAuth} from '../auth/AuthContext'
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};

export default function Roster() {

  const { venueUser} = useAuth();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("")
  const [rosters, setRosters] = useState([])
  const [venueName, setVenueName] = useState("")
  const [venueLogo, setVenueLogo] = useState("")

  useEffect(()=>{
    getRosters();
    getVenue()
  },[])

  const getRosters = () =>{
    axios.post(`${process.env.REACT_APP_API}/venue/get-rosters-by-id`,{_id: venueUser.uid})
    .then((res)=>setRosters(res.data))
    .catch((e)=>console.log(e))
  }

  const getVenue = () =>{
    axios.post(`${process.env.REACT_APP_API}/venue/get-venue-by-id`,{_id: venueUser.uid})
    .then((res)=>{
      setVenueName(res.data.venueName);setVenueLogo(res.data.logo)
    })
    .catch((e)=>console.log(e))
  }

  const handleSave = () =>{
    if(!title){
      toast.error("Please add a title")
    } else {

      axios.post(`${process.env.REACT_APP_API}/venue/create-roster`, 
      {rosterName: title,
        artists: [],
        venueId: venueUser.uid,
        venueName,
        venueLogo
      
      }).then(()=>{
          toast.success("Successfully added new roster")
          setTitle("")
          handleClose();
          getRosters();
        }).catch((e)=>console.log(e))
      
    }
  }

  return (
    <main>
      <button onClick={handleOpen} id='add-roster-btn'>Add new Roster +</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create New Roster
          </Typography>
          <TextField sx={{my:2}} variant='outlined' label='Roster Title e.g.Sunday Afternoon' fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
          <Button onClick={handleSave} variant="contained" endIcon={<SaveIcon />}>
            Save
          </Button>
        
        </Box>
      </Modal>

      {rosters.map((roster, i)=>{
        return(
          <div className='roster-container' key={i}>
            <h3>{roster.rosterName}</h3>
            <div className='roster-artist-container'>
            {roster.artists.length > 0 ? roster.artists.map((artist, i)=>{
              return(
                <div key={i} className='roster-artist'>
                  <img className='roster-img' src={artist.img} alt={artist.artistName}/>
                  <div>
                    <h5>{artist.artistName}</h5>
                    <p>{artist.category}</p>
                  </div>
                </div>
              )
            }) : <p>No Artists Added To This Roster</p>}
            </div>
          </div>
        )
      })}
    </main>
  )
}
