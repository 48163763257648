import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './CreateEvent.css'
//Step components
import Equipment from './Equipment';
import EventDetails from './EventDetails';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../auth/AuthContext';

export default function CreateEvent() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { venueUser} = useAuth();
  
    //event state
    const [oneOff, setOneOff] = useState(true)
    const [date, setDate ] = useState("")
    const [sets, setSets] = useState([])
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [roster, setRoster] = useState(undefined)
    const [rosterName, setRosterName] = useState("")
    const [smallPA, setSmallPA] = useState(true)
    const [mediumPA, setMediumPA] = useState(false)
    const [largePA, setLargePA] = useState(false)
    const [mixer, setMixer] = useState(true)
    const [mic, setMic] = useState(true)
    const [micStand, setMicStand] = useState(true)
    const [instrument, setInstrument] = useState(true)
    const [cables, setCables] = useState(true)
    const [xlr, setXlr] = useState(true)
    const [equipment, setEquipment] = useState([])
    const [artists, setArtists] = useState([])

    useEffect(()=>{
        if(roster){
            axios.post(`${process.env.REACT_APP_API}/venue/get-roster`, {_id: roster})
            .then((res)=>{
                setRosterName(res.data.rosterName)
                setArtists(res.data.artists)
            })
            .catch((e)=>console.log(e))
        } else {
            return
        }
        
    },[roster])

    const singlePayload = {title: "No Artist", venueId: venueUser.uid, start: date, sets, artists, equipment, status: "Unassigned", applied: [], backgroundColor: 'red', borderColor:'red', display:'block'}


    const stepOne = () =>{
        return(
            <div className='step-container'>
                <div className={oneOff? 'event-type-selected' : 'event-type-container'} onClick={()=>setOneOff(true)}>
                    <p>One-off</p>
                </div>
    
                <div className={oneOff? 'event-type-container' :'event-type-selected'} onClick={()=>setOneOff(false)}>
                    <p>Recurring</p>
                </div>
    
            </div>
        )
    }
    const stepFour = () =>{
        return(
            <table id='summary-table'>
                <tr>
                    <th>Event Type</th>
                    <td>{oneOff ? 'One-Off': 'Recurring'}</td>
                </tr>
                <tr>
                    <th>Date(s)</th>
                    <td>{date}</td>
                </tr>
                {sets.map((set, i)=>{
                    return(
                        <tr>
                            <th>Set {i +1}</th>
                            <td>{set.startTime} - {set.endTime}</td>
                        </tr>
                    )
                })}
                <tr>
                    <th>Roster</th>
                    <td>{rosterName}</td>
                </tr>
                <tr>
                    <th>Equipment</th>
                    <td><ul>{equipment.map((item, i)=> <li key={i}>{item}</li>)}</ul></td>
                </tr>           
            </table>
        )
    }

    const steps =
    //step 1
     [{label: 'Select event type', 
     component: stepOne()}, 
    //step 2
    {label:'Event details',
    component: <EventDetails 
            oneOff={oneOff} date={date} 
            setDate={setDate} sets={sets} 
            setSets={setSets} roster={roster} setRoster={setRoster}
            start={start} setStart={setStart}
            end={end} setEnd={setEnd}/>}, 
    //step 3
    {label:'Equipment', 
    component: <Equipment
            smallPA={smallPA} setSmallPA={setSmallPA}
            mediumPA={mediumPA} setMediumPA={setMediumPA}
            largePA={largePA} setLargePA={setLargePA}
            mixer={mixer} setMixer={setMixer}
            mic={mic} setMic={setMic}
            micStand={micStand} setMicStand={setMicStand}
            instrument={instrument} setInstrument={setInstrument}
            cables={cables} setCables={setCables}
            xlr={xlr} setXlr={setXlr} setEquipment={setEquipment}
            />}, 
     //Step 4
    {label:'Create event', 
    component: stepFour()}];

    const isStepOptional = (step) => {
      return step === -1;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleSubmit = () =>{
        if(oneOff){
            if(!date || !roster || !equipment || sets.length < 1 ){
                toast.error("Please add date, roster, equipment and sets")
            } else {
                axios.post(`${process.env.REACT_APP_API}/event/create-single-event`, singlePayload)
                .then((res)=>toast.success(res.data))
                .catch((e)=>toast.error("Oops, something went wrong"))
            }
        } else {
            console.log("Recurring")
        }
    }
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Booking completed - head back to the calendar to view
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
              {steps[activeStep].component}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
  
              <Button onClick={activeStep === steps.length - 1 ? handleSubmit :handleNext}>
                {activeStep === steps.length - 1 ? 'Create' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    );
  }
