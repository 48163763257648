import React, { useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Auth.css'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { venueAuth } from '../../firebase';
import { toast} from 'react-toastify'
import axios from 'axios'

export default function VenueSignUp() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")

  let navigate = useNavigate();

  const handleSubmit = (e) =>{
    e.preventDefault();

    if(!name || !email || !password){
      toast.error("Please fill in all fields")
    } else if(password !== confirm){
      toast.error("Passwords do not match")
    } else {
      createUserWithEmailAndPassword(venueAuth, email, password)
      .then((user) => {
        console.log(user)
        axios.post(`${process.env.REACT_APP_API}/venue/create-venue`,
        {_id: user.user.uid, venueName: name, 
          logo: "https://firebasestorage.googleapis.com/v0/b/venueauth.appspot.com/o/business-icon-png-1941.png?alt=media&token=b5c6ab51-4926-47d3-a423-f15d766996ee", 
          email,
          address: {
          lineOne: "",
          lineTwo: "",
          town: "",
          county: "",
          postcode: ""
        }}).then(()=>{
          toast.success("Successfully created account!");
          navigate('/')
        })
        .catch((e)=>toast.error("Oops, something went wrong"))
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }

  }

  return (
    <main id='signup-page'>
            <img className='auth-logo' src={require('../../images/logo-white.png')} alt='Music Gofer Logo'/>

    <form className='login-form'>
      <h1 className='auth-page-title'>Venue Sign Up</h1>
      <input className='login-input' type='text' value={name} onChange={(e)=>setName(e.target.value)} placeholder="Venue Name" />
      <input className='login-input' type='text' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email address" />
      <input className='login-input' type='password' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password"/>
      <input className='login-input' type='password' value={confirm} onChange={(e)=>setConfirm(e.target.value)} placeholder="Confirm Password"/>

      <button type='submit' className='auth-btn' onClick={handleSubmit}>Create Free Account</button>
      <p className='info'>Already got an account? <Link style={{color:'#007cd2'}} to="/login">Sign In</Link></p>
    </form>

  </main>
  )
}
