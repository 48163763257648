import React, { useEffect} from 'react'
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';

export default function Equipment(
  {smallPA, setSmallPA, mediumPA, 
    setMediumPA, largePA, setLargePA, 
    mixer, setMixer, mic, setMic, micStand, 
    setMicStand, instrument, setInstrument, 
    cables, setCables, xlr, setXlr, setEquipment}) {
    

    const equipment = [
         {"Small PA": smallPA}, {"Medium PA": mediumPA},{"Large PA": largePA},{"Mixer": mixer},{"Microphone": mic},{"Microphone Stand": micStand},{"Instruments": instrument},{"Instrument Cables": cables},{"XLR Cables": xlr},
    ]

    const filterEquipment = equipment.filter((item)=>{
      let value = Object.values(item)[0];
      return value === true
    })

    useEffect(()=>{
      let chosenEquipment = filterEquipment.map((item)=> Object.keys(item)[0])
      setEquipment(chosenEquipment)
    }, [smallPA, mediumPA, largePA, mixer, mic, micStand, instrument, cables, xlr])

  return (
    <div>
            <h4>Equipment required</h4>
           
            <Chip sx={{m:1}} size='large' label='Small PA' color={smallPA ? "success": "default"}  variant={smallPA ? "default": "outlined"} icon={smallPA && <DoneIcon />} onClick={()=>setSmallPA(!smallPA)} />
            <Chip sx={{m:1}} label='Medium PA' color={mediumPA ? "success": "default"}  variant={mediumPA ? "default": "outlined"} icon={mediumPA && <DoneIcon/>} onClick={()=>setMediumPA(!mediumPA)} />
            <Chip sx={{m:1}} label='Large PA' color={largePA ? "success": "default"}  variant={largePA ? "default": "outlined"} icon={largePA && <DoneIcon/>} onClick={()=>setLargePA(!largePA)} />
            <Chip sx={{m:1}} label='Mixer' color={mixer ? "success": "default"}  variant={mixer ? "default": "outlined"} icon={mixer && <DoneIcon />} onClick={()=>setMixer(!mixer)} />
            <Chip sx={{m:1}} label='Microphone' color={mic ? "success": "default"}  variant={mic ? "default": "outlined"} icon={mic && <DoneIcon />} onClick={()=>setMic(!mic)} />
            <Chip sx={{m:1}} label='Microphone Stand' color={micStand ? "success": "default"}  variant={micStand ? "default": "outlined"} icon={micStand && <DoneIcon />} onClick={()=>setMicStand(!micStand)} />
            <Chip sx={{m:1}} label='Instruments' color={instrument ? "success": "default"}  variant={instrument ? "default": "outlined"} icon={instrument && <DoneIcon />} onClick={()=>setInstrument(!instrument)} />
            <Chip sx={{m:1}} label='Instrument Cables' color={cables ? "success": "default"}  variant={cables ? "default": "outlined"} icon={cables && <DoneIcon />} onClick={()=>setCables(!cables)} />
            <Chip sx={{m:1}} label='XLR Cables' color={xlr ? "success": "default"}  variant={xlr ? "default": "outlined"} icon={xlr && <DoneIcon />} onClick={()=>setXlr(!xlr)} />

        </div>
  )
}
