import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseVenueConfig = {
  apiKey: "AIzaSyAnANJx-BrmyoQw2dufo1TJVJMJ_rmprMk",
  authDomain: "venueauth.firebaseapp.com",
  projectId: "venueauth",
  storageBucket: "venueauth.appspot.com",
  messagingSenderId: "788243950406",
  appId: "1:788243950406:web:2e9c71b169567d3d0a9347"
};

// Initialize Firebase
const venueApp = initializeApp(firebaseVenueConfig, "venue");

export const venueAuth = getAuth(venueApp);
export const storage = getStorage(venueApp);
