import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { signOut } from "firebase/auth";
import { venueAuth} from './firebase'
import './VenueUI.css'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import GroupsIcon from '@mui/icons-material/Groups';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';

export default function VenueUI() {

  const [open, setOpen] = useState(true)

  const toggleOpen =()=> {
    setOpen(!open)
  }

  const logout = () =>{
    signOut(venueAuth).then(() => {
      console.log("logged out")
    }).catch((error) => {
      console.log(error)
    });
  }
  
  return (
    <div>
     
        <nav className={open? 'sidenav-open' : 'sidenav-closed'}>
          <div style={{width: '100%', textAlign:'center'}}>
            <img id='nav-logo' src='https://firebasestorage.googleapis.com/v0/b/venueauth.appspot.com/o/logo-white.png?alt=media&token=41ff9b00-66ec-48b2-a6a1-37502db5bb6a' alt='Music Gofer Logo'/>
           
            <Link to={'/'} className='nav-item'>
                <SearchIcon sx={{color: '#fff'}}/>
                <p className='nav-title'>Find Artists</p>
            </Link>
            <Link to="roster" className='nav-item'>
                <GroupsIcon sx={{color: '#fff'}}/>
                <p className='nav-title'>Rosters</p></Link>
            <Link to={'calendar'} className='nav-item'>
                <CalendarTodayIcon sx={{color: '#fff'}}/>
                <p className='nav-title'>Calendar</p>
            </Link>
            <Link to={'bookings'} className='nav-item'>
                <LibraryMusicIcon sx={{color: '#fff'}}/>
                <p className='nav-title'>Bookings</p>
            </Link>     

            <Link to="settings"className='nav-item'>
              <SettingsIcon sx={{color: '#fff'}}/>
              <p className='nav-title'>Settings</p>

            </Link>

          </div>
         
          <button onClick={logout} id='logout-btn'>Logout</button>
        </nav>
     
      <div id={open ? 'main-open': 'main-closed'}>
        <Outlet />
      </div>
    </div>
  )
}
