import React, { useState, useEffect} from 'react'
import './CreateEvent.css'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast} from 'react-toastify'
import axios from 'axios'
import { useAuth } from '../auth/AuthContext'

export default function EventDetails({oneOff, date, setDate, sets, setSets, start, setStart, end, setEnd, roster, setRoster}) {
    
    const { venueUser} = useAuth();
    const [rosters, setRosters] = useState([])

    useEffect(()=>{
        axios.post(`${process.env.REACT_APP_API}/venue/get-rosters-by-id`,{_id: venueUser.uid})
        .then((res)=>{
            setRosters(res.data)
        }).catch((e)=>console.log(e))
    },[])

    const clearData = () =>{
        setStart("");
        setEnd("")
    }

    const confirmSet=()=>{
        if(!start || !end){
            toast.error("Please add start and end time")
        } else {
            let newSet = {startTime: start, endTime: end}
            setSets([...sets, newSet])
            clearData();
        }
    }

  return (
    <div style={{textAlign: 'center'}}>
        {oneOff ? 
        <div>
             <h3>Date of event</h3>
            <input className='date-input' type='date' value={date} onChange={(e)=>setDate(e.target.value)} />

            <select id='event-roster-select' value={roster} onChange={(e)=>setRoster(e.target.value)}>
                <option value="" >Select Roster</option>
                {rosters.length > 0 && rosters.map((option, i)=>{
                    return(
                        <option value={option._id} key={i}>{option.rosterName}</option>
                    )
                })}
            </select>

            {sets.length > 0 && sets.map((set, i)=>{


                return (
                    <div key={i} className='set-container'>
                        <h3>Set {i +1}</h3>
                        <p>{set.startTime} - {set.endTime}</p>
                        <DeleteForeverIcon sx={{color: '#f00e0e'}} fontSize='large'/>
                    </div>
                )
            
            })}

                    <div >
                        <h3>Set {sets.length +1}</h3>
                        <label>From</label>
                        <input className='set-time' type='time' value={start} onChange={(e)=> setStart(e.target.value)}/>
                        <label>To</label>
                        <input className='set-time' type='time' value={end} onChange={(e)=> setEnd(e.target.value)}/>
                        <button id='confirm-set-btn' onClick={confirmSet}>Confirm Set Time</button>
                    </div>

            
        </div>:
        <p>Recurring</p>
        }
       

    </div>
  )
}
