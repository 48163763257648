import React, { useState, useEffect} from 'react'
import './Dashboard.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext'
import {toast} from 'react-toastify'

let categories = ["Mellow", "Lively", "Duo", "Band", "Tribute"]

export default function Dashboard() {
  const { venueUser } = useAuth();
  const [openModal, setOpenModal] = useState(false)
  const [mellow, setMellow] = useState([])
  const [lively, setLively] = useState([])
  const [duo, setDuo] = useState([])
  const [band, setBand] = useState([])
  const [tribute, setTribute] = useState([])
  const [rosters, setRosters] = useState([])
  const [roster, setRoster] = useState("")
  const [artist, setArtist] = useState("")


  const handleModal = (artist) =>{
    setArtist(artist)
    setOpenModal(!openModal)
  }

  useEffect(()=>{
    categories.map((category)=>{
      getCategory(category)
    })

    getRosters();
  },[])

  const getCategory = (category) =>{
    axios.post(`${process.env.REACT_APP_API}/acts/get-category`, {category})
    .then((res)=>{
      if(category === "Mellow"){
        setMellow(res.data)
      } else if(category === "Lively"){
        setLively(res.data)
      } else if(category === "Duo"){
        setDuo(res.data)
      } else if(category === "Band"){
        setBand(res.data)
      } else if(category === "Tribute"){
        setTribute(res.data)
      }
      console.log(res.data)
    })
    .catch((e)=>console.log(e))
  }

  const getRosters = () =>{
    axios.post(`${process.env.REACT_APP_API}/venue/get-rosters-by-id`, {_id: venueUser.uid})
    .then((res)=>setRosters(res.data))
    .catch((e)=>console.log(e))
  }

  const handleConfirm = () =>{
    console.log(roster, artist)
    axios.post(`${process.env.REACT_APP_API}/roster/add-to-roster`, {roster, artist})
    .then((res)=>{
        axios.post(`${process.env.REACT_APP_API}/roster/add-roster-to-artist`, {roster, artist})
        .then((res)=>{
            toast.success("Added successfully")
        })
        .catch((e)=>toast.error(e.response.data))
    })
    .catch((e)=>toast.error(e.response.data))
  }

  return (
    <main>

      <div id={openModal? 'modal-open':'modal-closed'}>
        <label>Please select a Roster to add to</label>
        <select id='roster-select' value={roster} onChange={(e)=>setRoster(e.target.value)}>
          <option value="">Select Roster Roster</option>
          {rosters.length > 0 && rosters.map((roster, i)=>{
            return <option key={i} value={roster._id}>{roster.rosterName}</option>
          })}
        </select>
          {artist && roster && <button onClick={handleConfirm}>Confirm</button>}
        <button className='close-modal' onClick={()=>{setOpenModal(!openModal); setArtist("");setRoster("")}}>Close</button>
      </div>

      <h4>Find Musicians</h4>
      {categories.map((category,i)=>{
        return (
          <div key={i}>
          <h3>{category}</h3>

          <div  className='category-container'>
            <Carousel responsive={responsive}>
            {category == "Mellow" && mellow.map((artist, i)=>{
                  return(
                    <div key={i} className='artist-container'>
                      <img className='carousel-img' src={artist.img} />
                      <button className="artist-btn">{artist.actName}</button>
                      <button className='add-to-roster-btn' onClick={()=>handleModal(artist)}>+ Add to Roster</button>
                    </div>
                  )
                })}

            {category == "Lively" && lively.map((artist, i)=>{
                  return(
                    <div key={i} className='artist-container'>
                      <img className='carousel-img' src={artist.img} />
                      <button className="artist-btn">{artist.actName}</button>
                      <button className='add-to-roster-btn' onClick={()=>handleModal(artist)}>+ Add to Roster</button>
                    </div>
                  )
                })}
              {category == "Duo" && duo.map((artist, i)=>{
                  return(
                    <div key={i} className='artist-container'>
                      <img className='carousel-img' src={artist.img} />
                      <button className="artist-btn">{artist.actName}</button>
                      <button className='add-to-roster-btn' onClick={()=>handleModal(artist)}>+ Add to Roster</button>
                    </div>
                  )
                })}
              {category == "Band" && band.map((artist, i)=>{
                  return(
                    <div key={i} className='artist-container'>
                      <img className='carousel-img' src={artist.img} />
                      <button className="artist-btn">{artist.actName}</button>
                      <button className='add-to-roster-btn' onClick={()=>handleModal(artist)}>+ Add to Roster</button>
                    </div>
                  )
                })}

              {category == "Tribute" && tribute.map((artist, i)=>{
                  return(
                    <div key={i} className='artist-container'>
                      <img className='carousel-img' src={artist.img} />
                      <button className="artist-btn">{artist.actName}</button>
                      <button className='add-to-roster-btn' onClick={()=>handleModal(artist)}>+ Add to Roster</button>
                    </div>
                  )
                })}     
              </Carousel>
          </div>
          </div>
        )
      })}
    </main>
  )
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

