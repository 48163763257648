import React, { useState, useEffect} from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './Bookings.css'
import { toast} from 'react-toastify'
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment-timezone'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RRule } from 'rrule'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAuth} from '../auth/AuthContext'

export default function Bookings() {
    const { venueUser} = useAuth();

    const [type, setType] = useState("One-Off")
    const [date, setDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [venue, setVenue] = useState("")
    const [venueName, setVenueName] = useState("")
    const [address, setAddress] = useState("")
    const [equipment, setEquipment] = useState([
        { key: 0, label: 'PA' },
        { key: 1, label: 'Mixer' },
        { key: 2, label: 'Microphone' },
        { key: 3, label: 'Mic Stand' },
        { key: 4, label: 'Instruments' },
        { key: 5, label: 'Cables' },
      ]);    
    const [setTimes, setSetTimes] = useState([])
    const [artistFee, setArtistFee] = useState("")
    const [notes, setNotes] = useState("")
    const [newItem, setNewItem] = useState("")
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [weekdays, setWeekdays] = useState([]);
    const [rosters, setRosters] = useState([])
    const [roster, setRoster] = useState("")

    useEffect(()=>{
        getRosters();
        getVenue()
      },[])
    
    const getRosters = () =>{
        axios.post(`${process.env.REACT_APP_API}/venue/get-rosters-by-id`,{_id: venueUser.uid})
        .then((res)=>setRosters(res.data))
        .catch((e)=>console.log(e))
    }

    const getVenue = () =>{
        axios.post(`${process.env.REACT_APP_API}/venue/get-venue-by-id`,{_id: venueUser.uid})
        .then((res)=>setVenueName(res.data.venueName))
        .catch((e)=>console.log(e))
    }
   

    const handleDelete = (itemToDelete) => () => {
        setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
      };

    const clearData =()=>{
        setEquipment([]);setSetTimes([]);setArtistFee(""); setDate("");setStartDate("");setEndDate("");setNotes("");setAddress(""); setSetTimes([]);setWeekdays([])

    }

    const createOneOff = () =>{
        if(!artistFee || !roster || !date || setTimes.length < 1){
            toast.error("Please add all required fields")
        } else{
            const singlePayload = {title: "No Artist", venueId: venueUser.uid, venueName, start: moment(`${date}T${setTimes[0].from}`).tz("Europe/London").format(), artistFee, rosterId: roster, setTimes, equipment, status: "Unassigned", notes, applied: [], backgroundColor: 'red', borderColor:'red', display:'block', artistId: "", artistName:""}

            axios.post(`${process.env.REACT_APP_API}/event/new-event`, singlePayload)
            .then(()=>{ clearData();
                toast.success('Successfully created')})
            .catch((e)=>toast.error("Oops, something went wrong"))
        }
       
    }

    const createRecurring = () =>{
       if(!startDate || !endDate || !artistFee || !roster || setTimes.length < 1){
            toast.error("Add all required fields")
       } else {
        const rule = new RRule({
            freq: RRule.WEEKLY,
            dtstart: moment(`${startDate} ${setTimes[0].from}`).tz('Europe/London').toDate(),
            until: moment(`${endDate} ${setTimes[0].to}`).toDate(),
            count: 30,
            interval: 1,
            byweekday: weekdays
          })

          rule.all().map((date)=>{
            let payload = { start: moment(date).tz("Europe/London").format(), title: "No Artist", venueId: venueUser.uid, venueName, artistFee, rosterId: roster, setTimes, equipment, status: "Unassigned", notes, applied: [], backgroundColor: 'red', borderColor:'red', display:'block', artistId: "", artistName:""}
            axios.post(`${process.env.REACT_APP_API}/event/new-event`, payload)
            .then(()=>{ 
                console.log("Added")
            })
            .catch((e)=>toast.error("Oops, something went wrong"))
          })
          clearData();
          toast.success("Events Created")
       }
    }

    const addItem = (e)=>{
        if(e.key === 'Enter'){
          console.log(newItem)
          setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
          setNewItem("")
        }
      }

      const removeSet = (set) =>{
        setSetTimes((item) => item.filter((item) => item.key !== set.key));
      } 
  
      const addSet = () =>{
        if(!from || !to){
          toast.error("Please add from and to time")
        } else {
          let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
          setSetTimes([...setTimes, {key,from, to}])
          setTo("");setFrom("")
        }
      }

      const handleDays = (e) =>{
        let value = e.target.value;

        if(weekdays.includes(value)){
            setWeekdays((day) => day.filter((day) => day !== value));
        } else {
            setWeekdays([...weekdays, value])
        }
      }

  return (
    <main id='booking-container'>
        <ToggleButtonGroup sx={{my: 3}}
        color="primary"
        value={type}
        exclusive
        onChange={(e)=>setType(e.target.value)}
        aria-label="Platform"
        >
            <ToggleButton value="One-Off">One-Off</ToggleButton>
            <ToggleButton value="Recurring">Recurring</ToggleButton>
        </ToggleButtonGroup>

       
       <Box sx={{ minWidth: 120, maxWidth: 700, margin: '0 auto', textAlign: 'center' }}>

        {type === 'One-Off' ?
        <div style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', alignItems: 'center'}}> 
            <label className='date-label' >Event Date</label>
            <input className='booking-date' type='date' value={date} onChange={(e)=>setDate(e.target.value)}/>
        </div>
        :
        <div> 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap:'wrap'}}>
                <label className='date-label' >Date From</label>
                <input className='booking-date' type='date' value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>
            
                <label className='date-label' >Date To</label>
                <input className='booking-date' type='date' value={endDate} onChange={(e)=>setEndDate(e.target.value)}/>
            </div>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel value={RRule.MO} control={<Checkbox />} label="M" labelPlacement="top" onChange={handleDays} />
                    <FormControlLabel value={RRule.TU} control={<Checkbox />} label="T" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.WE} control={<Checkbox />} label="W" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.TH} control={<Checkbox />} label="T" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.FR} control={<Checkbox />} label="F" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.SA} control={<Checkbox />} label="S" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.SU} control={<Checkbox />} label="S" labelPlacement="top" onChange={handleDays}/>
                </FormGroup>
            </FormControl>
        </div>
            
        }
                <FormControl fullWidth sx={{my:1}}>
                    <InputLabel id="demo-simple-select-label">Select Roster</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roster}
                    label="Select Roster"
                    onChange={(e)=>setRoster(e.target.value)}
                    >
                    {rosters.length > 0 && rosters.map((roster, i)=>{
                        return <MenuItem value={roster._id} key={i}>{roster.rosterName}</MenuItem>
                    })}
                    </Select>
                </FormControl>

                <TextField sx={{my:1}} variant='outlined' fullWidth label='Artist Fee' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
                <TextField sx={{my:1}} variant='outlined' fullWidth multiline rows={3} label='Booking Notes' value={notes} onChange={(e)=>setNotes(e.target.value)} />
                <h3 className='booking-header'>Set Times</h3>
               
                {setTimes.length > 0 ? 
                <div>
                    {setTimes.map((set, i)=>{
                        return <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 className='booking-header'>Set {i+1}</h4>
                        <p className='booking-info'>{set.from} - {set.to}</p>
                        <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                      </div>
                    })}
                </div>
                : <p className='booking-info'>No set times added</p>}
                <label>From</label>
                <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
                <label>To</label>
                <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
                <button onClick={addSet} style={{backgroundColor: '#025E91', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
                
                <h3 className='booking-header'>Equipment</h3>
                {equipment.length > 0 ? 
                <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
                    {equipment.map((data) => {
                    let icon;
                    return (
                        <ListItem key={data.key}>
                        <Chip icon={icon} label={data.label} onDelete={handleDelete(data)} />
                        </ListItem>
                    );
                    })}
                        <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>

                </Paper>
                : <p className='booking-info'>No equipment added</p>}
                
                {type === "One-Off" && <Button variant='contained' sx={{backgroundColor:'#025E91'}} onClick={createOneOff}>Create Event</Button>}
                {type === "Recurring" && <Button variant='contained' sx={{backgroundColor:'#025E91'}} onClick={createRecurring}>Create Recurring</Button>}
            </Box>

    </main>
  )
}
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  