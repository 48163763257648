import { createContext, useContext, useEffect, useState } from 'react'
import {  onAuthStateChanged } from "firebase/auth";
import { artistAuth, venueAuth } from '../../firebase';

const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider( {children} ){

    const [venueUser, setVenueUser] = useState(null)
    const [isVenueLoading, setVenueIsLoading] = useState(true)
  
useEffect(() => {
   
    let unsubscribe= onAuthStateChanged(venueAuth, (venue) => {
        if (venue) {
            console.log(venue)
            setVenueUser(venue)
            setVenueIsLoading(false)
        } else {
            setVenueUser(null)
            setVenueIsLoading(false)
        }
      });

    return unsubscribe;
  }, []);



    const value = {
         venueUser
    }
    
    return (
        <AuthContext.Provider value={value}>
            {!isVenueLoading && children}
        </AuthContext.Provider>

    )
}

