import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//components
import VenueUI from './VenueUI';
import Dashboard from './components/venues/Dashboard';
import VenueLogin from './components/auth/VenueLogin';
import VenueSignUp from './components/auth/VenueSignUp';
import Roster from './components/venues/Roster'
import Calendar from './components/venues/Calendar'
import Settings from './components/venues/Settings'
import VenueAuth from './components/auth/VenueAuth';
import { AuthProvider } from "./components/auth/AuthContext";
import CreateEvent from './components/venues/CreateEvent';
import Bookings from './components/venues/Bookings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <ToastContainer position='top-center'/>
    <BrowserRouter>
      <Routes>
        {/*Unauthenticated routes*/}
       
        <Route path="/signup" element={< VenueSignUp/>} />
        <Route path="/login" element={< VenueLogin/>} />
         
        
        {/*Venue authenticated routes*/}
        <Route   element={<VenueAuth  />} >
          <Route path="/" element={<VenueUI />}>
            <Route index element={< Dashboard/>} />
            <Route path="roster" element={<Roster />}/>
            <Route path="calendar" element={<Calendar />}/>
            <Route path="new-event" element={<CreateEvent />}/>
            <Route path="bookings" element={<Bookings />}/>

            <Route path="settings" element={<Settings />}/>
          </Route>
        </Route>

  
      </Routes>
    </BrowserRouter>
  </AuthProvider>,
);



