import React, { useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';

export default function Calendar() {

  let navigate = useNavigate();
  const { venueUser} = useAuth();
  const [events, setEvents] = useState([])

  useEffect(()=>{
    getEvents();
  },[])

  const getEvents = () =>{
    axios.post(`${process.env.REACT_APP_API}/event/get-events`, {venueId: venueUser.uid})
    .then((res)=> setEvents(res.data))
    .catch((e)=>console.log(e))
  }

  return (
    <main>
      
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={events}
      />
    </main>
  )
}
