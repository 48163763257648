import { Button, TextField } from '@mui/material'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import './Settings.css'
import { useAuth } from '../auth/AuthContext'
import { toast } from 'react-toastify'

export default function Settings() {

  const [logo, setLogo] = useState("https://firebasestorage.googleapis.com/v0/b/venueauth.appspot.com/o/business-icon-png-1941.png?alt=media&token=b5c6ab51-4926-47d3-a423-f15d766996ee")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [lineOne, setLineOne] = useState("")
  const [lineTwo, setLineTwo] = useState("")
  const [town, setTown] = useState("")
  const [county, setCounty] = useState("")
  const [postcode, setPostcode] = useState("")

  const { venueUser} = useAuth();

  useEffect(()=>{
    getCompany()
  },[])

  const getCompany = () =>{
    axios.post(`${process.env.REACT_APP_API}/venue/get-venue-by-id`, {_id: venueUser.uid})
    .then((res)=>{
      console.log(res.data)
      setName(res.data.venueName);
      setLogo(res.data.logo);
      setEmail(res.data.email)
      const {address} = res.data;
      setLineOne(address.lineOne);setLineTwo(address.lineTwo);setTown(address.town);setCounty(address.county);setPostcode(address.postcode)
    }
    )
    .catch((e)=>console.log(e))
  }

  const handleUpdate = () =>{
    if(!name || !postcode || !lineOne){
      toast.error("Please ensure a venue name and basic address is added")
    } else {
      axios.post(`${process.env.REACT_APP_API}/venue/update-venue`, {_id: venueUser.uid, payload:{logo, venueName:name, address:{lineOne, lineTwo, town, county, postcode} }})
      .then((res)=>{
        console.log(res.data)
        toast.success("Successfully Updated")
      })
      .catch((e)=>{console.log(e); toast.error("Oops, something went wrong")})
    }
  }


  return (
    <main id='settings-container'>
      <img id='company-logo' src={logo} alt='company-logo'/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Name' value={name} onChange={(e)=>{setName(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Email' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Address Line 1*' value={lineOne} onChange={(e)=>{setLineOne(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Address Line 2' value={lineTwo} onChange={(e)=>{setLineTwo(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Town' value={town} onChange={(e)=>{setTown(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='County' value={county} onChange={(e)=>{setCounty(e.target.value)}}/>
      <TextField sx={{my:1}} variant='outlined' fullWidth label='Postcode*' value={postcode} onChange={(e)=>{setPostcode(e.target.value)}}/>

      <Button variant='contained' sx={{backgroundColor: '#025E91'}} onClick={handleUpdate}>Update</Button>
    </main>
  )
}
