import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';


const VenueAuth = () => {

    const {venueUser} = useAuth()
    let location = useLocation();

    return venueUser  ? <Outlet /> : <Navigate to="/login" state={{from : location}} replace/> 

};

export default VenueAuth;